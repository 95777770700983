//AIzaSyD2eYk1XGReBY6UyFCFL4mjwxvjY3zqmHc
import { useEffect } from 'react'
import GoogleMapReact, { Maps } from 'google-map-react'
import { useKMLStore, useUserStore } from './DataStore'

const Map = () => {
  const kml = useKMLStore((state: any) => state.kml)
  const fetchKML = useKMLStore((state: any) => state.fetch)

  const managing_zc = useUserStore((state: any) => state.managing_zc)

  useEffect(() => {
    if (!kml) {
      fetchKML(managing_zc)
    }
  }, [fetchKML, kml, managing_zc])

  return (
    <div style={{ height: '87vh', width: '100%' }}>
      <GoogleMapReact
        key={kml}
        options={(maps: Maps) => ({
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeId: maps.MapTypeId.HYBRID,
          zoomControl: true,
          clickableIcons: false,
        })}
        bootstrapURLKeys={{ key: 'AIzaSyD2eYk1XGReBY6UyFCFL4mjwxvjY3zqmHc' }}
        defaultCenter={{
          lat: 39.557191,
          lng: -7.8536599,
        }}
        defaultZoom={7}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          const kmlLayer = new maps.KmlLayer({
            url: kml || '',
          })
          kmlLayer.setMap(map)
        }}
      ></GoogleMapReact>
    </div>
  )
}

export default Map
