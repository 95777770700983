import styled from 'styled-components'

import Home from './home'
import Features from './features'
import Footer from './footer'

import { Message } from 'primereact/message'

const Landing = ({
  activeMenu,
  shouldShowMessage,
}: {
  activeMenu: string
  shouldShowMessage: boolean
}) => {
  return (
    <PageWrapper>
      {shouldShowMessage && (
        <Message
          severity="warn"
          text="Por questões de segurança, apenas podemos dar acesso aos dados das Zonas de Caça a quem provar por direito ter acesso a tais informações. Se é um gestor/diretor de um clube de caça, envie um email para geral@sercacador.com a expor a situação para que lhe seja dado acesso, ou ligue: 910 834 344"
        />
      )}
      <Features activeMenu={activeMenu} />
      <Home />
      <Footer />
    </PageWrapper>
  )
}

export default Landing

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  max-height: calc(100vh - 100px);
  overflow-y: scroll;
`
