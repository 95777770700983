import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'

import styled from 'styled-components'

const Gate = ({ supabase }: { supabase: any }) => {
  return (
    <LoginWrapper>
      <Auth
        supabaseClient={supabase}
        appearance={{ theme: ThemeSupa }}
        providers={['google']}
        localization={{
          variables: {
            sign_up: {
              email_label: 'Endereço de Email',
              password_label: 'Criar Palavra-passe',
              email_input_placeholder: 'Endereço de email',
              password_input_placeholder: 'Palavra-passe',
              button_label: 'Registar',
              loading_button_label: 'A Registar ...',
              social_provider_text: 'Registar com {{provider}}',
              link_text: 'Ainda não tem conta? Registe-se',
              confirmation_text:
                'Verifique o seu email, irá receber um link de confirmação',
            },
            sign_in: {
              email_label: 'Endereço de email',
              password_label: 'Palavra-passe',
              email_input_placeholder: 'Endereço de email',
              password_input_placeholder: 'Palavra-passe',
              button_label: 'Entrar',
              loading_button_label: 'A entrar ...',
              social_provider_text: 'Entrar com {{provider}}',
              link_text: 'Já tem uma conta? Faça login',
            },
            forgotten_password: {
              email_label: 'Endereço de email',
              password_label: 'Palavra-passe',
              email_input_placeholder: 'Endereço de email',
              button_label: 'Enviar instruções para recuperar palavra-passe',
              loading_button_label: 'A enviar instruções para o seu email ...',
              link_text: 'Esqueceu-se da palavra-passe?',
              confirmation_text:
                'Verifique o seu email, enviámos um email para repor a palavra-passe',
            },
            update_password: {
              password_label: 'Nova palavra-passe',
              password_input_placeholder: 'Nova palavra-passe',
              button_label: 'Atualizar palavra-passe',
              loading_button_label: 'A atualizar palavra-passe ...',
              confirmation_text: 'A palavra-passe foi atualizada',
            },
          },
        }}
      />
    </LoginWrapper>
  )
}

export default Gate

const LoginWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
`
