import { useEffect, useState } from 'react'

import { ConfirmDialog } from 'primereact/confirmdialog'
import { Sidebar } from 'primereact/sidebar'
import { Card } from 'primereact/card'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'

import ManageDrivenHunt from './ManageDrivenHunt'

import styled from 'styled-components'
import { toast } from 'react-toastify'

import { useDrivenStore, useUserStore } from './DataStore'

const drivenInitialForm = {
  name: '',
  date: new Date(),
}

const DrivenHunts = () => {
  const hunts = useDrivenStore((state: any) => state.hunts)
  const fetchHunts = useDrivenStore((state: any) => state.fetch)
  const deleteHunts = useDrivenStore((state: any) => state.delete)
  const addHunts = useDrivenStore((state: any) => state.add)
  const setManaging_hunt = useDrivenStore(
    (state: any) => state.setManaging_hunt
  )
  const resetManagingHunt = useDrivenStore(
    (state: any) => state.resetManagingHunt
  )
  const managing_hunt = useDrivenStore((state: any) => state.managing_hunt)
  const managing_zc = useUserStore((state: any) => state.managing_zc)

  const [visible, setVisible] = useState<any>(false)
  const [addFormVisible, setAddFormVisible] = useState(false)
  const [drivenForm, setDrivenForm] = useState(drivenInitialForm)

  useEffect(() => {
    if (!hunts) {
      fetchHunts(managing_zc)
    }

    return () => {
      resetManagingHunt()
    }
  }, [hunts, fetchHunts, managing_zc, setManaging_hunt, resetManagingHunt])

  const accept = () => deleteHunts(visible, managing_zc)

  const renderDrivenHunts = () => {
    if (!hunts) {
      return null
    }

    const header = (image: string) => (
      <img style={{ width: '100%' }} alt="Card" src={image} />
    )

    const footer = (hunt: any) => (
      <ActionWrapper>
        <Button
          label="Gerir"
          onClick={() => setManaging_hunt(hunt)}
          icon="pi pi-pencil"
        />
        <Button
          label="Remover"
          onClick={() => setVisible(hunt.id)}
          icon="pi pi-times"
          className="p-button-outlined p-button-secondary"
        />
      </ActionWrapper>
    )

    return hunts.map((hunt: any) => (
      <CardWrapper>
        <Card
          title={hunt.name}
          subTitle={new Date(hunt.date).toLocaleDateString('pt-PT')}
          footer={footer(hunt)}
          header={header(hunt.image)}
        />
      </CardWrapper>
    ))
  }

  const isFormValid = () => {
    const temp = Object.values(drivenForm).filter((item) => item)

    let result = false

    if (temp.length < Object.keys(drivenInitialForm).length) {
      toast.error('Tem de preencher todos os campos')
      result = false
    } else {
      result = true
    }

    return result
  }

  if (managing_hunt) {
    return <ManageDrivenHunt />
  }

  return (
    <Maintenance>
      <Sidebar
        visible={addFormVisible}
        position="right"
        onHide={() => {
          setAddFormVisible(false)
          setDrivenForm(drivenInitialForm)
        }}
        style={{ width: '500px' }}
      >
        <h2>Criar Montaria</h2>
        <p>Nome da Montaria</p>
        <InputText
          style={{ width: '100%' }}
          value={drivenForm.name}
          placeholder="Ex: Montaria Novembro 2023"
          onChange={(e) =>
            setDrivenForm({ ...drivenForm, name: e.target.value })
          }
        />
        <p>Data da Montaria</p>
        <Calendar
          minDate={new Date()}
          style={{ width: '100%' }}
          value={new Date(drivenForm.date)}
          placeholder="Data"
          onChange={(e) =>
            setDrivenForm({ ...drivenForm, date: e.value as Date })
          }
          dateFormat="dd/mm/yy"
        />
        <Button
          style={{ width: '100%', marginTop: '20px' }}
          label={'Criar'}
          onClick={() => {
            if (isFormValid()) {
              addHunts(drivenForm, managing_zc)
              setDrivenForm(drivenInitialForm)
              setAddFormVisible(false)
            }
          }}
        />
      </Sidebar>
      <ConfirmDialog
        acceptClassName="p-button-danger"
        acceptLabel="Sim"
        rejectLabel="Não"
        visible={Boolean(visible)}
        onHide={() => setVisible(false)}
        message="Tem a certeza que pretende remover esta Montaria e todos os seus dados?"
        header="Confirmar Remoção"
        icon="pi pi-exclamation-triangle"
        accept={accept}
      />
      <Button
        style={{ marginBottom: '20px' }}
        label={'Criar Montaria'}
        icon="pi pi-plus"
        onClick={() => {
          if (hunts && hunts.length < 3) {
            setAddFormVisible(true)
          } else {
            toast.error(
              'Máximo de 3 Montarias, tem de remover uma para conseguir adicionar mais'
            )
          }
        }}
      />
      <DrivenWrapper>{renderDrivenHunts()} </DrivenWrapper>
    </Maintenance>
  )
}

export default DrivenHunts

const Maintenance = styled.div`
  padding: 20px;
  font-size: 22px;
`

const DrivenWrapper = styled.div`
  display: flex;
`

const CardWrapper = styled.div`
  width: calc(100% / 3.1);
  margin-right: 20px;
`

const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
