import { useEffect, useState, useRef } from 'react'

import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'
import { Sidebar } from 'primereact/sidebar'

import styled from 'styled-components'

import { toast } from 'react-toastify'

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  pdf,
} from '@react-pdf/renderer'

import { useAssociateStore, useZCStore, useUserStore } from './DataStore'
import saveAs from 'file-saver'

const docStyles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: '10pt',
    lineHeight: 2,
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '20pt',
  },
  nif: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '7pt',
    marginTop: '-16pt',
  },
  credentialNumber: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '14pt',
    marginTop: 20,
    marginBottom: 20,
  },
  section1: {
    fontSize: '10pt',
    textAlign: 'left',
  },
  identity: {
    fontSize: '10pt',
    textAlign: 'left',
    fontWeight: 'bold',
    marginTop: 40,
  },
  warnings: {
    fontSize: '8pt',
    textAlign: 'left',
    marginTop: 20,
  },
  date: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '16pt',
    marginTop: 50,
    marginBottom: 10,
  },
  sign: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 10,
    fontSize: '14pt',
  },
  transport: {
    fontSize: '12pt',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    //flexGrow: 1,
    //justifyContent: 'center',
  },
})

const credentialsTypes = [
  { label: 'Esperas Noturnas - Período Lunar', value: '0' },
  //{ label: 'Javali - Controlo de Densidade', value: '1' },
  //{ label: 'Perdiz', value: '2' },
  //{ label: 'Coelho', value: '3' },
  //{ label: 'Lebre', value: '4' },
  //{ label: 'Tordo', value: '5' },
]

const Credentials = () => {
  const associates = useAssociateStore((state: any) => state.associates)
  const fetchAssociates = useAssociateStore((state: any) => state.fetch)

  const managing_zc = useUserStore((state: any) => state.managing_zc)

  const zcData = useZCStore((state: any) => state.zc)
  const fetchZcData = useZCStore((state: any) => state.fetch)

  const [selectedAss, setSelectedAss] = useState<any>([])

  const [selectedCredential, setSelectedCredential] = useState<any>(undefined)

  const [isModalOpen, setIsModalOpen] = useState(false)

  let calendarElement = useRef<any>(undefined)
  const [dates, setDates] = useState<Array<Date | null | undefined>>([
    new Date(),
    new Date(),
  ])

  useEffect(() => {
    if (!associates) {
      fetchAssociates(managing_zc)
    }

    if (!zcData) {
      fetchZcData(managing_zc)
    }
  }, [associates, fetchAssociates, fetchZcData, managing_zc, zcData])

  const DocumentEsperasPdf = ({ data }: { data: any }) => (
    <Document>
      <Page size="A4" style={docStyles.page}>
        <View style={docStyles.title}>
          <Text>{zcData.name}</Text>
        </View>
        <View style={docStyles.nif}>
          <Text>{`NIF: ${zcData.nif}`}</Text>
        </View>
        <View style={docStyles.credentialNumber}>
          <Text>CREDENCIAL Nº ______</Text>
        </View>
        <View style={docStyles.section1}>
          <Text>{`A Direção da Associação de Caçadores e Pescadores de Paranhos da Beira autoriza o Sr.(a) ${data.name} a efetuar esperas noturnas aos javalis nas propriedades sitas em _______________________________
          a fim de proteger as culturas de _______________________________`}</Text>
          <Text>{`com início a ${dates[0]?.toLocaleDateString(
            'pt-PT'
          )} e término a ${dates[1]?.toLocaleDateString(
            'pt-PT'
          )} devendo esta credencial ser devolvida à entidade emissora findo o
          período supramencionado.`}</Text>
        </View>
        <View style={docStyles.identity}>
          <Text>{`Nome do Caçador: ${data.name}`}</Text>
          <Text>{`Licença Nº: ${data.license_id}`}</Text>
          <Text>{`C.C. Nº: ${data.civil_id}`}</Text>
          <Text>Validade C.C.: ___ / ___ / ______</Text>
          <Text>
            Apólice de Seguro Nº:
            ______________________________________________________________
          </Text>
          <Text>Validade do Seguro: ___ / ___ / ______</Text>
          <Text>
            Companhia de Seguros:
            _____________________________________________________________
          </Text>
        </View>
        <View style={docStyles.warnings}>
          <Text>{`As esperas a efetuar estão previstas no Plano de Ordenamento e Exploração Cinegética da ${zcData.type}, processo Nº ${zcData.process_id}/AFN, e serão efetuadas durante o “Período de Lua Cheia”, de acordo com o Decreto-Lei Nº 202/2004 de 18 de Agosto, com a redação que lhe foi conferida pelo Decreto-Lei 201/05 de 24 de Novembro. (“Período de Lua Cheia” - "é o período que decorre entre as oito noites que antecedem a lua cheia, a noite de lua cheia e a noite seguinte").`}</Text>
        </View>
        <View style={docStyles.date}>
          <Text>{`${zcData.place}, ${new Date().toLocaleDateString('pt-PT', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}`}</Text>
        </View>
        <View style={docStyles.sign}>
          <Text>A Direção</Text>
          <Text>Carimbo</Text>
        </View>
        <View style={docStyles.transport}>
          <Text>
            Este documento serve como guia de transporte dos animais abatidos
            para a viatura da marca ______________________________ com a
            matricula _________________________________ .
          </Text>
        </View>
      </Page>
    </Document>
  )

  const downloadPdf = (arrData: Array<any>) => {
    arrData.forEach(async (element: any) => {
      const blob = await pdf(<DocumentEsperasPdf data={element} />).toBlob()
      saveAs(blob, `Credencial_${element.name.replace(' ', '_')}`)
    })
  }

  const tableHeader = (
    <ActionContainer>
      <Button
        label={'Gerar Credencial'}
        icon="pi pi-plus"
        onClick={() => {
          if (selectedAss.length > 0) {
            setIsModalOpen(true)
          } else {
            toast.error('Deve selecionar pelo menos um associado')
          }
        }}
      />
    </ActionContainer>
  )

  return (
    <Wrapper>
      <Sidebar
        visible={isModalOpen}
        position="right"
        onHide={() => {
          setIsModalOpen(false)
          setSelectedCredential(undefined)
        }}
        style={{ width: '500px' }}
      >
        <p>Tipo de Credencial</p>
        <Dropdown
          style={{ width: '100%' }}
          value={selectedCredential}
          onChange={(e) => setSelectedCredential(e.target.value)}
          options={credentialsTypes}
          placeholder="Escolha uma Credencial"
        />
        <p>Intervalo de Datas</p>
        <Calendar
          style={{ width: '100%' }}
          ref={calendarElement}
          dateFormat="dd/mm/yy"
          placeholder="Intervalo de Datas"
          value={dates as Array<Date>}
          selectionMode="range"
          onChange={(e) => {
            const start = (e.value as any)[0]
            const end = (e.value as any)[1]
            if (start && end) {
              calendarElement.current.hide()
            }
            setDates(e.value as Array<Date>)
          }}
        />
        <Button
          style={{ width: '100%', marginTop: '20px' }}
          label={'Gerar Credencial'}
          icon="pi pi-download"
          onClick={() => {
            if (!selectedCredential) {
              toast.error('Deve selecionar uma credencial')
              return
            }
            downloadPdf(selectedAss)
          }}
        />
      </Sidebar>
      <DataTable
        value={associates}
        stripedRows
        size="normal"
        scrollable
        scrollHeight="77vh"
        selection={selectedAss}
        onSelectionChange={(e) => setSelectedAss(e.value)}
        header={tableHeader}
      >
        <Column
          selectionMode="multiple"
          headerStyle={{ width: '3rem' }}
        ></Column>
        <Column sortable field="name" header="Nome" />
        <Column field="ass_nr" header="Número de Sócio" />
        <Column field="license_id" header="Número Licença" />
      </DataTable>
    </Wrapper>
  )
}

export default Credentials

const Wrapper = styled.div`
  padding: 10px;
`
const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
