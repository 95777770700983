import {
  Container,
  Titulo,
  Ilustracao,
  Card,
  TituloPrincipal,
  ParagrafoPrincipal,
  Texto,
  SubTitle,
} from "./styled";

import visitorSocios from '../images/visitorsocios.png'
import visitorQuotas from '../images/visitorquotas.png'
import visitorCustos from '../images/visitorcustos.png'
import visitorCredenciais from '../images/visitorcredenciais.png'
import visitorComms from '../images/visitorcomms.png'
import visitorMap from '../images/visitormap.png'
import visitorDriven from '../images/visitordriven.png'

function Features({activeMenu}) {

  const getMenuContent = () => {

    if (activeMenu === 'socios') {
      return <Card>
      <Ilustracao>
        <img src={visitorSocios} alt="Listagem de Sócios" />
      </Ilustracao>
      <Texto>
        <Titulo>Listagem de Sócios</Titulo>
        <ParagrafoPrincipal>Adicionar Sócios</ParagrafoPrincipal>
        <ParagrafoPrincipal>Remover Sócios</ParagrafoPrincipal>
        <ParagrafoPrincipal>Editar Sócios</ParagrafoPrincipal>
        <ParagrafoPrincipal>Controlar dados dos Sócios</ParagrafoPrincipal>
      </Texto>
    </Card>
    }

    if (activeMenu === 'quotas') {
      return <Card>
      <Ilustracao>
        <img src={visitorQuotas} alt="features" />
      </Ilustracao>
      <Texto>
        <Titulo>Controlo de Quotas</Titulo>
        <ParagrafoPrincipal>Listar o estado das Quotas por Sócio</ParagrafoPrincipal>
        <ParagrafoPrincipal>Confirmar pagamentos</ParagrafoPrincipal>
        <ParagrafoPrincipal>Filtrar pagamentos em falta</ParagrafoPrincipal>
      </Texto>
    </Card>
    }

    if (activeMenu === 'custos') {
      return <Card>
      <Ilustracao>
        <img src={visitorCustos} alt="features" />
      </Ilustracao>
      <Texto>
        <Titulo>Gestão de Custos</Titulo>
        <ParagrafoPrincipal>Adicionar despesas</ParagrafoPrincipal>
        <ParagrafoPrincipal>Adicionar receitas</ParagrafoPrincipal>
        <ParagrafoPrincipal>Remover custos</ParagrafoPrincipal>
        <ParagrafoPrincipal>Exportar custos por intervalo de datas</ParagrafoPrincipal>
      </Texto>
    </Card>
    }

    if (activeMenu === 'credenciais') {
      return <Card>
      <Ilustracao>
        <img src={visitorCredenciais} alt="features" />
      </Ilustracao>
      <Texto>
        <Titulo>Gerar Credenciais</Titulo>
        <ParagrafoPrincipal>Gerar credencias para esperas por Sócio</ParagrafoPrincipal>
        <ParagrafoPrincipal>Gerar credenciais para convidados por método de caça</ParagrafoPrincipal>
      </Texto>
    </Card>
    }

    if (activeMenu === 'comunicacao') {
      return <Card>
      <Ilustracao>
        <img src={visitorComms} alt="features" />
      </Ilustracao>
      <Texto>
        <Titulo>Comunicação aos Sócios</Titulo>
        <ParagrafoPrincipal>Gerar etiquetas de moradas para todos os Sócios</ParagrafoPrincipal>
        <ParagrafoPrincipal>Gerar etiquetas de moradas por Sócios</ParagrafoPrincipal>
        <ParagrafoPrincipal>Enviar SMS para todos os Sócios</ParagrafoPrincipal>
        <ParagrafoPrincipal>Enviar SMS por Sócio, individualmente</ParagrafoPrincipal>
        <ParagrafoPrincipal>Verificar dados de contacto de todos os Sócios</ParagrafoPrincipal>
      </Texto>
    </Card>
    }

    if (activeMenu === 'territorial') {
      return <Card>
      <Ilustracao>
        <img src={visitorMap} alt="features" />
      </Ilustracao>
      <Texto>
        <Titulo>Gestão Territorial</Titulo>
        <ParagrafoPrincipal>Visualizar no mapa os limites da ZCA, ZCM, ZCT, ...</ParagrafoPrincipal>
      </Texto>
    </Card>
    }

    if (activeMenu === 'montarias') {
      return <Card>
      <Ilustracao>
        <img src={visitorDriven} alt="features" />
      </Ilustracao>
      <Texto>
        <Titulo>Gestão de Montarias</Titulo>
        <ParagrafoPrincipal>Organizar Montarias</ParagrafoPrincipal>
        <ParagrafoPrincipal>Calcular custos</ParagrafoPrincipal>
        <ParagrafoPrincipal>Gerar convites automaticamente</ParagrafoPrincipal>
        <ParagrafoPrincipal>Controlar inscrições</ParagrafoPrincipal>
        <ParagrafoPrincipal>Controlar pagamentos</ParagrafoPrincipal>
        <ParagrafoPrincipal>Exportar ficheiros por postor ou linha</ParagrafoPrincipal>
      </Texto>
    </Card>
    }

    return null

  }

  return (
    <Container>
    <TituloPrincipal>HuntiGest - Um pacote completo para uma gestão eficaz</TituloPrincipal>
    <SubTitle>Tudo num só lugar, acessivel de qualquer ponto.</SubTitle>
    {getMenuContent()}
    </Container>
  );
}

export default Features