import { useState, useEffect, useCallback } from 'react'
import { createClient } from '@supabase/supabase-js'
import styled from 'styled-components'

import { Image } from 'primereact/image'
import { Dialog } from 'primereact/dialog'
import { Menubar } from 'primereact/menubar'

import Gate from './Gate'
import Socios from './Socios'
import Payments from './Payments'
import Map from './Map'
import Costs from './Costs'
import Credentials from './Credentials'
import Comms from './Comms'
import DrivenHunts from './DrivenHunts'

/* VISITOR */
import Landing from './visitor/VisitorViews'
/* VISITOR */

import {
  useUserStore,
  useZCStore,
  useCostStore,
  useKMLStore,
  useAssociateStore,
  useDrivenStore,
} from './DataStore'

const menus = [
  { title: 'Sócios', icon: 'pi-id-card', id: 'socios' },
  { title: 'Quotas', icon: 'pi-download', id: 'quotas' },
  { title: 'Contabilidade', icon: 'pi-dollar', id: 'contabilidade' },
  { title: 'Credenciais', icon: 'pi-file', id: 'credenciais' },
  { title: 'Comunicação', icon: 'pi-phone', id: 'comunicacao' },
  { title: 'Território', icon: 'pi-map', id: 'territorial' },
  { title: 'Montarias', icon: 'pi-calendar-plus', id: 'montarias' },
]

const supabase = createClient(
  process.env.REACT_APP_SUP_API_URL as string,
  process.env.REACT_APP_SUP_ANON_KEY as string
)

const App = () => {
  const resetUser = useUserStore((state: any) => state.resetUser)
  const resetZCStore = useZCStore((state: any) => state.resetZCSStore)
  const resetCostStore = useCostStore((state: any) => state.resetCostStore)
  const resetKMLStore = useKMLStore((state: any) => state.resetKMLStore)
  const resetAssociateStore = useAssociateStore(
    (state: any) => state.resetAssociateStore
  )
  const resetDrivenStore = useDrivenStore(
    (state: any) => state.resetDrivenStore
  )

  const user = useUserStore((state: any) => state.user)
  const fetchUser = useUserStore((state: any) => state.fetch)

  const zcData = useZCStore((state: any) => state.zc)
  const fetchZcData = useZCStore((state: any) => state.fetch)
  const managingZc = useUserStore((state: any) => state.managing_zc)
  const setManagingZc = useUserStore((state: any) => state.setManagingZc)

  const availableZCs = useUserStore((state: any) => state.available_zcs)

  const [activeMenu, setActiveMenu] = useState<string>('socios')

  const [session, setSession] = useState<any>(null)

  const [logoutDialog, setLogoutDialog] = useState(false)

  const resetAllStores = useCallback(() => {
    resetUser()
    resetAssociateStore()
    resetZCStore()
    resetCostStore()
    resetKMLStore()
    resetDrivenStore()
  }, [
    resetAssociateStore,
    resetCostStore,
    resetDrivenStore,
    resetKMLStore,
    resetUser,
    resetZCStore,
  ])

  useEffect(() => {
    if (user && !zcData) {
      fetchZcData(managingZc)
    }
  }, [fetchZcData, managingZc, user, zcData])

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    const { data } = supabase.auth.onAuthStateChange((_event, session) => {
      if (session?.user.aud === 'authenticated') {
        setActiveMenu('socios')
        setLogoutDialog(false)
        if (session && !user) {
          fetchUser(session.user.id)
        }
      } else {
        resetAllStores()
      }

      setSession(session)
    })

    return () => data.subscription.unsubscribe()
  }, [fetchUser, user, resetAllStores, zcData])

  const MenuItem = (menu: any) => {
    return (
      <StyledMenuIconWrapper
        key={menu.id}
        controlid={activeMenu === menu.id}
        onClick={() => setActiveMenu(menu.id)}
      >
        <i
          className={`pi ${menu.icon}`}
          style={{
            fontSize: '1.5rem',
            color: activeMenu === menu.id ? 'white' : '#fda700',
          }}
        />
        <p>{menu.title}</p>
      </StyledMenuIconWrapper>
    )
  }

  const getMenuContent = (
    status: 'full' | 'partial' | 'nothing'
  ): JSX.Element | null => {
    if (status === 'full') {
      switch (activeMenu) {
        case 'socios':
          return <Socios />
        case 'quotas':
          return <Payments />
        case 'territorial':
          return <Map />
        case 'contabilidade':
          return <Costs />
        case 'credenciais':
          return <Credentials />
        case 'comunicacao':
          return <Comms />
        case 'montarias':
          return <DrivenHunts />
        default:
          return null
      }
    }

    return (
      <Landing activeMenu={activeMenu} shouldShowMessage={session && !user} />
    )
  }

  const getContentBasedOnAccountStatus = () => {
    if (session && user) {
      return getMenuContent('full')
    }

    if (session && !user) {
      return getMenuContent('partial')
    }

    return getMenuContent('nothing')
  }

  const getNavMenu = () => {
    let items = []

    if (session) {
      const generateZCItems = () => {
        return availableZCs.map((zc: any) => ({
          label: zc,
          icon: managingZc === zc ? 'pi pi-fw pi-check' : undefined,
          command: () => {
            if (managingZc !== zc) {
              setManagingZc(zc)
              resetAssociateStore()
              resetZCStore()
              resetCostStore()
              resetDrivenStore()
              resetKMLStore()
            }
          },
        }))
      }

      if (user) {
        items = [
          {
            label: `A Gerir: ${managingZc || ''}`,
            icon: 'pi pi-fw pi-map',
          },
          {
            label: 'Zonas de Caça',
            icon: 'pi pi-fw pi-home',
            items: generateZCItems(),
          },
          {
            label: session.user.email,
            icon: 'pi pi-fw pi-cog',
            items: [
              {
                label: 'Terminar Sessão',
                icon: 'pi pi-fw pi-power-off',
                command: () => {
                  supabase.auth.signOut()
                  resetAllStores()
                },
              },
            ],
          },
        ]
      } else {
        items = [
          {
            label: session.user.email,
            icon: 'pi pi-fw pi-cog',
            items: [
              {
                label: 'Terminar Sessão',
                icon: 'pi pi-fw pi-power-off',
                command: () => {
                  supabase.auth.signOut()
                  resetAllStores()
                },
              },
            ],
          },
        ]
      }
    } else {
      items = [
        {
          label: 'Entrar',
          icon: 'pi pi-fw pi-user',
          command: () => setLogoutDialog(true),
        },
      ]
    }

    return <Menubar model={items} style={{ zIndex: 9 }} />
  }

  return (
    <AppFrame>
      <Dialog
        header={'HuntiGest - Gestão Cinegética'}
        visible={logoutDialog}
        style={{ width: '50vw' }}
        onHide={() => setLogoutDialog(false)}
      >
        <Gate supabase={supabase} />
      </Dialog>
      <SideMenu>
        <LogoContainer>
          <Image
            src="/huntigest_branco.png"
            alt="HuntiGest-Logo"
            width="150px"
          />
        </LogoContainer>
        {menus.map((menu: any) => MenuItem(menu))}
      </SideMenu>
      <MainContainer>
        <NavBar>{getNavMenu()}</NavBar>
        {getContentBasedOnAccountStatus()}
      </MainContainer>
    </AppFrame>
  )
}

const AppFrame = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: #e9ecef;
`

const SideMenu = styled.div`
  width: 290px;
  height: 100%;
  background-color: #f9fafe;
`

const LogoContainer = styled.div`
  background-color: #fda700;
  width: 290px;
  height: 100px;
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
`
const MainContainer = styled.div`
  width: 100%;
  height: 100%;
`

const StyledMenuIconWrapper = styled.div<{ controlid: boolean }>`
  display: flex;
  padding: 20px;
  width: 100%;
  font-weight: ${(props) => (props.controlid ? 700 : 400)};
  background-color: ${(props) => (props.controlid ? '#fda700' : 'unset')};
  color: ${(props) => (props.controlid ? 'white' : 'unset')};
  align-items: center;

  p {
    margin: 0 0 0 20px;
  }

  &:hover {
    cursor: pointer;
    background-color: ${(props) => (props.controlid ? '#fda700' : '#ffd580c3')};
  }
`

const NavBar = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100px;
  background-color: #323232;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
`

export default App
