import { ContainerFooter, Logo, Rota, Conjunto, Conjunto2, Rede } from "./styled"
import logo from "../images/huntigest_branco.png"

export default function Footer() {
  return (
    <ContainerFooter>
      <Conjunto>
          <Logo src={logo} alt="logo" />
          <Rota>geral@sercacador.com</Rota>
      </Conjunto>

      <Conjunto2>
        <Rede href="https://www.facebook.com/sercacador" target="_blank">
          <i className="pi pi-facebook" style={{ fontSize: '40px', color: 'white', margin: '30px' }}></i>
        </Rede>

        <Rede href="https://www.instagram.com/sercacador/" target="_blank">
          <i className="pi pi-instagram" style={{ fontSize: '40px', color: 'white', margin: '30px' }}></i>
        </Rede>
      </Conjunto2>

    </ContainerFooter>
  )
}
