import { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { reject } from 'lodash'

import { TabMenu } from 'primereact/tabmenu'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Sidebar } from 'primereact/sidebar'
import { InputText } from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'
import { AutoComplete } from 'primereact/autocomplete'
import { Dropdown } from 'primereact/dropdown'
import { InputSwitch } from 'primereact/inputswitch'

import { useDrivenStore, useAssociateStore } from './DataStore'
import { toast } from 'react-toastify'

const lineFormInitial = { name: '', postor: '' }

const monteiroFormInitial = {
  name: '',
  license_id: '',
  phone: undefined,
  email: '',
  persons: 0,
  price: 0,
  paid: 0,
}

const ManageDrivenHunt = () => {
  const associates = useAssociateStore((state: any) => state.associates)

  const managing_hunt = useDrivenStore((state: any) => state.managing_hunt)
  const resetManagingHunt = useDrivenStore(
    (state: any) => state.resetManagingHunt
  )
  const lines = useDrivenStore((state: any) => state.lines)
  const updateLines = useDrivenStore((state: any) => state.updateLines)
  const fetchSingle = useDrivenStore((state: any) => state.fetchSingle)

  const monteiros = useDrivenStore((state: any) => state.monteiros)
  const fetchMonteiros = useDrivenStore((state: any) => state.fetchMonteiros)
  const addMonteiro = useDrivenStore((state: any) => state.addMonteiro)
  const deleteMonteiro = useDrivenStore((state: any) => state.deleteMonteiro)
  const updateLineDoorMonteiro = useDrivenStore(
    (state: any) => state.updateLineDoorMonteiro
  )

  const [activeIndex, setActiveIndex] = useState(0)
  const [addingLine, setAddingLine] = useState(false)
  const [lineForm, setLineForm] = useState(lineFormInitial)

  const [addingMonteiro, setAddingMonteiro] = useState(false)
  const [monteiroForm, setMonteiroForm] = useState(monteiroFormInitial)

  useEffect(() => {
    if (!lines) {
      fetchSingle(managing_hunt.id, managing_hunt.process_id)
    }

    if (!monteiros) {
      fetchMonteiros(managing_hunt.id, managing_hunt.process_id)
    }
  }, [
    fetchMonteiros,
    fetchSingle,
    lines,
    managing_hunt.id,
    managing_hunt.process_id,
    monteiros,
  ])

  const getRightContent = () => {
    switch (activeIndex) {
      case 0:
        return (
          <div>
            <Button
              style={{ marginRight: '10px' }}
              label="Adicionar Monteiro"
              onClick={() => setAddingMonteiro(true)}
              icon="pi pi-plus"
            />
            <Button
              label="Exportar Inscrições"
              onClick={() => exportExcel('Inscrições')}
              icon="pi pi-download"
            />
          </div>
        )
      case 1:
        return (
          <Button
            label="Exportar Sócios"
            onClick={() => exportExcel('Sócios')}
            icon="pi pi-download"
          />
        )
      case 2:
        return (
          <Button
            label="Exportar Convidados"
            onClick={() => exportExcel('Convidados')}
            icon="pi pi-download"
          />
        )
      case 3:
        return (
          <Button
            label="Adicionar Linha"
            onClick={() => setAddingLine(true)}
            icon="pi pi-plus"
          />
        )
      default:
        return null
    }
  }

  const memoMonteiros = useMemo(() => {
    const all = monteiros || []
    const socios = (monteiros || []).filter((mont: any) => mont.ass_id)
    const convidados = (monteiros || []).filter((mont: any) => !mont.ass_id)

    return { all, socios, convidados }
  }, [monteiros])

  const tabItems = [
    {
      label: `Inscrições - ${memoMonteiros.all.length}`,
      icon: 'pi pi-fw pi-users',
    },
    {
      label: `Sócios - ${memoMonteiros.socios.length}`,
      icon: 'pi pi-fw pi-users',
    },
    {
      label: `Convidados - ${memoMonteiros.convidados.length}`,
      icon: 'pi pi-fw pi-users',
    },
    { label: `Linhas - ${(lines || []).length}`, icon: 'pi pi-fw pi-minus' },
  ]

  const deleteLineBodyTemplate = (rowData: any) => (
    <Button
      size="small"
      icon="pi pi-times"
      severity="danger"
      onClick={() => {
        const rawLines = JSON.parse(JSON.stringify(managing_hunt.lines))

        updateLines(
          reject(rawLines, rowData),
          managing_hunt.id,
          managing_hunt.process_id
        )
      }}
    />
  )

  const deleteBodyTemplate = (rowData: any) => (
    <Button
      size="small"
      icon="pi pi-times"
      severity="danger"
      onClick={() =>
        deleteMonteiro(rowData.id, rowData.hunt_id, rowData.process_id)
      }
    />
  )

  const lineEditor = (options: any) => {
    const { rowData } = options

    return (
      <Dropdown
        value={options.value}
        onChange={(e) => {
          options.editorCallback(e.target.value)
          updateLineDoorMonteiro(
            rowData.id,
            e.target.value,
            rowData.door,
            rowData.paid,
            rowData.hunt_id,
            rowData.process_id
          )
        }}
        options={lines}
        optionValue="name"
        optionLabel="name"
        placeholder="Escolha uma linha"
      />
    )
  }

  const onLineEditComplete = (e: any) => {
    let { rowData, newValue, field } = e

    rowData[field] = newValue
  }

  const doorEditor = (options: any) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => {
          options.editorCallback(e.target.value)
        }}
      />
    )
  }

  const onDoorEditComplete = (e: any) => {
    let { rowData, newValue, field } = e

    updateLineDoorMonteiro(
      rowData.id,
      rowData.line,
      newValue,
      rowData.paid,
      rowData.hunt_id,
      rowData.process_id
    )

    rowData[field] = newValue
  }

  const paidEditor = (options: any) => {
    return (
      <InputSwitch
        checked={Boolean(options.value)}
        onChange={(e) => {
          options.editorCallback(e.value ? 1 : 0)
        }}
      />
    )
  }

  const onPaidEditComplete = (e: any) => {
    let { rowData, newValue, field } = e

    updateLineDoorMonteiro(
      rowData.id,
      rowData.line,
      rowData.door,
      newValue ? 1 : 0,
      rowData.hunt_id,
      rowData.process_id
    )

    rowData[field] = newValue ? 1 : 0
  }

  const saveAsExcelFile = (buffer: BlobPart, fileName: string) => {
    import('file-saver').then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        let EXCEL_EXTENSION = '.xlsx'
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        })

        module.default.saveAs(data, fileName + EXCEL_EXTENSION)
      }
    })
  }

  const translateData = () =>
    memoMonteiros.all.map((mont: any) => ({
      Nome: mont.name,
      'Carta de Caçador': mont.license_id,
      Sócio: mont.ass_id || 'Não',
      Acompanhantes: mont.persons,
      Contacto: mont.phone,
      Email: mont.email,
      Linha: mont.line,
      Porta: mont.door,
    }))

  const exportExcel = (name: string) => {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(translateData())
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] }
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      })

      saveAsExcelFile(excelBuffer, `Dados Montaria - ${name}`)
    })
  }

  const tableHeader = (
    <ActionContainer>
      <div style={{ display: 'flex' }}>
        <Button
          label="Voltar"
          size="small"
          onClick={() => resetManagingHunt()}
          icon="pi pi-chevron-left"
        />
        <TabMenu
          model={tabItems}
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        />
      </div>
      {getRightContent()}
    </ActionContainer>
  )

  const getMainContent = () => {
    let tableMonteiros = memoMonteiros.all

    if (activeIndex === 1) {
      tableMonteiros = memoMonteiros.socios
    }

    if (activeIndex === 2) {
      tableMonteiros = memoMonteiros.convidados
    }

    switch (activeIndex) {
      case 0:
      case 1:
      case 2:
        return (
          <DataTable
            value={tableMonteiros}
            stripedRows
            size="normal"
            scrollable
            scrollHeight="73vh"
            header={tableHeader}
          >
            <Column sortable field="name" header="Nome" />
            <Column field="license_id" header="Carta Caçador" />
            <Column field="ass_id" header="Sócio" />
            <Column field="phone" header="Telemovel" />
            <Column field="email" header="Email" />
            <Column field="persons" header="Acompanhantes" />
            <Column field="price" header="Preço" />
            <Column
              sortable
              field="paid"
              header="Pago"
              editor={paidEditor}
              onCellEditComplete={onPaidEditComplete}
              body={(rowData) => (Boolean(rowData.paid) ? 'Sim' : 'Não')}
            />
            <Column
              field="line"
              header="Linha"
              editor={lineEditor}
              onCellEditComplete={onLineEditComplete}
            />
            <Column
              field="door"
              header="Porta"
              editor={doorEditor}
              onCellEditComplete={onDoorEditComplete}
            />
            <Column body={deleteBodyTemplate} header="Remover Monteiro" />
          </DataTable>
        )
      case 3:
        return (
          <DataTable
            value={lines}
            stripedRows
            size="normal"
            scrollable
            scrollHeight="73vh"
            header={tableHeader}
          >
            <Column field="name" header="Linha" />
            <Column field="postor" header="Postor" />
            <Column body={deleteLineBodyTemplate} header="Remover Linha" />
          </DataTable>
        )
      default:
        break
    }
  }

  const isLineFormValid = () => {
    const temp = Object.values(lineForm).filter((item) => item)

    let result = false

    if (temp.length < Object.keys(lineFormInitial).length) {
      toast.error('Tem de preencher todos os campos')
      result = false
    } else {
      result = true
    }

    return result
  }

  const [value, setValue] = useState('')
  const [items, setItems] = useState<any>(associates)

  const setSearchValue = (val: any) => {
    setValue(val)
    setMonteiroForm({
      name: val.name,
      license_id: val.license_id,
      phone: val.phone,
      email: val.email,
      persons: 0,
      ass_id: val.ass_nr,
    } as any)
  }

  const search = (event: any) => {
    let _filteredCountries

    if (!event.query.trim().length) {
      _filteredCountries = [...associates]
    } else {
      _filteredCountries = associates.filter((ass: any) => {
        return ass.name.toLowerCase().includes(event.query.toLowerCase())
      })
    }

    setItems(_filteredCountries)
  }

  return (
    <Wrapper>
      <Sidebar
        visible={addingLine}
        position="right"
        onHide={() => {
          setAddingLine(false)
          setLineForm(lineFormInitial)
        }}
        style={{ width: '500px' }}
      >
        <h2>Adicionar Linha</h2>
        <p>Nome</p>
        <InputText
          style={{ width: '100%' }}
          value={lineForm.name}
          placeholder="Nome"
          onChange={(e) => setLineForm({ ...lineForm, name: e.target.value })}
        />
        <p>Postor</p>
        <InputText
          style={{ width: '100%' }}
          value={lineForm.postor}
          placeholder="Postor"
          onChange={(e) => setLineForm({ ...lineForm, postor: e.target.value })}
        />
        <Button
          style={{ width: '100%', marginTop: '20px' }}
          label={'Adicionar'}
          onClick={() => {
            if (isLineFormValid()) {
              updateLines(
                [...(managing_hunt.lines || []), lineForm],
                managing_hunt.id,
                managing_hunt.process_id
              )
            } else {
              return
            }

            setLineForm(lineFormInitial)
            setAddingLine(false)
          }}
        />
      </Sidebar>
      <Sidebar
        visible={addingMonteiro}
        position="right"
        onHide={() => {
          setAddingMonteiro(false)
          setMonteiroForm(monteiroFormInitial)
          setValue('')
        }}
        style={{ width: '500px' }}
      >
        <h2>Adicionar Monteiro</h2>
        <p>Pesquisar Sócio</p>
        <AutoComplete
          style={{ width: '100%' }}
          field={'name'}
          value={value}
          suggestions={items}
          completeMethod={search}
          onChange={(e) => setSearchValue(e.value)}
          dropdown
        />
        <p>Nome</p>
        <InputText
          style={{ width: '100%' }}
          value={monteiroForm.name}
          placeholder="Nome"
          onChange={(e) =>
            setMonteiroForm({ ...monteiroForm, name: e.target.value })
          }
        />
        <p>Carta de Caçador</p>
        <InputText
          style={{ width: '100%' }}
          value={monteiroForm.license_id}
          placeholder="Carta de Caçador"
          onChange={(e) =>
            setMonteiroForm({ ...monteiroForm, license_id: e.target.value })
          }
        />
        <p>Telefone</p>
        <InputNumber
          style={{ width: '100%' }}
          useGrouping={false}
          value={monteiroForm.phone}
          placeholder="Telefone"
          onChange={(e) =>
            setMonteiroForm({ ...monteiroForm, phone: String(e.value) as any })
          }
        />
        <p>Email</p>
        <InputText
          style={{ width: '100%' }}
          value={monteiroForm.email}
          placeholder="Email"
          onChange={(e) =>
            setMonteiroForm({ ...monteiroForm, email: e.target.value })
          }
        />
        <p>Acompanhantes</p>
        <InputNumber
          style={{ width: '100%' }}
          useGrouping={false}
          value={monteiroForm.persons}
          placeholder="Acompanhantes"
          defaultValue={0}
          onChange={(e) =>
            setMonteiroForm({
              ...monteiroForm,
              persons: String(e.value) as any,
            })
          }
        />
        <p>Preço</p>
        <InputNumber
          style={{ width: '100%' }}
          useGrouping={false}
          value={monteiroForm.price}
          placeholder="Preço"
          onChange={(e) =>
            setMonteiroForm({ ...monteiroForm, price: e.value as number })
          }
        />
        <p>Pagamento Efectuado?</p>
        <InputSwitch
          checked={Boolean(monteiroForm.paid)}
          onChange={(e) =>
            setMonteiroForm({ ...monteiroForm, paid: e.value ? 1 : 0 })
          }
        />
        <Button
          style={{ width: '100%', marginTop: '20px' }}
          label={'Adicionar'}
          onClick={() => {
            addMonteiro(
              monteiroForm,
              managing_hunt.id,
              managing_hunt.process_id
            )

            setMonteiroForm(monteiroFormInitial)
            setAddingMonteiro(false)
          }}
        />
      </Sidebar>
      {getMainContent()}
    </Wrapper>
  )
}

export default ManageDrivenHunt

const Wrapper = styled.div`
  padding: 20px;
`

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
