import styled from "styled-components";

export const Container = styled.div`
  padding: 20px;
`;

export const Linha = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 800px) {
    flex-direction: column-reverse;
  }
`;

export const Textos = styled.div`
  max-width: 28rem;
  @media (max-width: 800px) {
    text-align: center;
    margin-top: 4rem;
  }
`;

export const ColunaImagem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  ::after {
    content: "";
    position: absolute;
    right: -6rem;
    bottom: -2rem;
    width: 90%;
    height: 80%;
    z-index: -2;
    border-top-left-radius: 30% 50%;
    border-bottom-left-radius: 30% 50%;
    @media (max-width: 800px) {
      right: -2rem;
    }
  }
`;

export const Paragrafo = styled.p`
  line-height: 1.5;
  margin-bottom: 2rem;
`;
export const Titulo = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;
`;

export const TituloPrincipal = styled.h1`
  text-align: center;
  font-size: 3rem;
  margin-bottom: 0.7rem;
  @media (max-width: 800px) {
    margin-top: 7rem;
    text-align: center;
  }
`;

export const ParagrafoPrincipal = styled.p`
  margin-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  font-size: 1rem;
  margin-bottom: 10px;
`;

export const SubTitle = styled.p`
  margin-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 2rem;
  padding: 0;
  @media (max-width: 800px) {
    justify-content: center;
    flex-direction: column;
  }
`;

export const Ilustracao = styled.div`
  position: relative;
  ::after {
    content: "";
    position: absolute;
    left: -10rem;
    bottom: -4rem;
    width: 120%;
    height: 95%;
    z-index: -1;
    border-top-right-radius: 30% 50%;
    border-bottom-right-radius: 30% 50%;
  }
  img {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 50rem;
    @media (max-width: 800px) {
      width: 20rem;
    }
  }
`;

export const Texto = styled.div`
  margin: 2rem;
`;

export const Conjunto = styled.div`
  display: flex;
  flex-direction: row;
  color: ${(props) => props.theme.white};
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Conjunto2 = styled.div`
  flex-direction: row;
  color: ${(props) => props.theme.white};
  @media (max-width: 800px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const Logo = styled.img`
  width: 150px;
  padding: 0.7rem;
  margin-right: 3rem;
`;

export const ContainerFooter = styled.footer`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #323232;
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Rota = styled.p`
  display: flex;
  align-items: center;
  margin: 1rem;
  color: white;
`;

export const Rede = styled.a``;