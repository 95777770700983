import { useState, useEffect, useMemo } from 'react'

import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Chip } from 'primereact/chip'
import { TabMenu } from 'primereact/tabmenu'
import { Dialog } from 'primereact/dialog'
import { InputNumber } from 'primereact/inputnumber'

import styled from 'styled-components'

import { useAssociateStore, useUserStore, useCostStore } from './DataStore'

const tabItems = [
  { label: 'Todos', icon: 'pi pi-fw pi-list' },
  { label: 'Em Falta', icon: 'pi pi-fw pi-download' },
]

const Payments = () => {
  const associates = useAssociateStore((state: any) => state.associates)
  const fetchAssociates = useAssociateStore((state: any) => state.fetch)
  const updatePayment = useAssociateStore((state: any) => state.updatePayment)
  const addCosts = useCostStore((state: any) => state.add)

  const [confirmationDialogVisible, setConfirmationDialogVisible] =
    useState<any>(false)
  const [yearToPay, setYearToPay] = useState(Number(new Date().getFullYear()))

  const managing_zc = useUserStore((state: any) => state.managing_zc)

  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    if (!associates) {
      fetchAssociates(managing_zc)
    }
  }, [associates, fetchAssociates, managing_zc])

  const renderPaymentsPills = (rowData: any) => {
    return rowData.payments.map((year: number) => (
      <Chip
        key={`${rowData.license_id}${String(year)}`}
        label={String(year)}
        style={{
          backgroundColor: 'lightgreen',
          color: 'white',
          margin: '2px',
        }}
      />
    ))
  }

  const renderMissingPaymentsPills = (rowData: any) => {
    const now = Number(new Date().getFullYear())
    const years = []

    for (let index = 2023; index <= now; index++) {
      years.push(index)
    }

    const intersection = years.filter((x) => !rowData.payments.includes(x))

    return intersection.map((year: number) => (
      <Chip
        key={`${rowData.license_id}${String(year)}`}
        label={String(year)}
        style={{
          backgroundColor: 'red',
          color: 'white',
          margin: '2px',
        }}
      />
    ))
  }

  const renderCurrentYearPayment = (rowData: any) => {
    const now = Number(new Date().getFullYear())
    const years = []

    for (let index = 2023; index <= now; index++) {
      years.push(index)
    }

    const intersection = years.filter((x) => !rowData.payments.includes(x))

    if (intersection.length === 0) {
      return null
    } else {
      return (
        <Button
          label={'Confirmar Pagamento'}
          onClick={() => {
            setConfirmationDialogVisible(rowData)
          }}
        />
      )
    }
  }

  const memoSocios = useMemo(() => {
    if (activeIndex === 1) {
      const currentYear = Number(new Date().getFullYear())
      return (associates || []).filter(
        (soc: any) => !soc.payments.includes(currentYear)
      )
    }

    return associates
  }, [activeIndex, associates])

  return (
    <Wrapper>
      <Dialog
        header="Confirmar Pagamento de Quota"
        visible={confirmationDialogVisible}
        style={{ width: '50vw' }}
        onHide={() => {
          if (confirmationDialogVisible) {
            setConfirmationDialogVisible(false)
          }
        }}
      >
        <p className="m-0">
          Insira o ano que deseja efectuar a confirmação de Pagamento
        </p>
        <InputNumber
          style={{ width: '100%', marginBottom: '10px' }}
          useGrouping={false}
          value={yearToPay}
          placeholder="Ano a pagamento"
          onChange={(e) => {
            setYearToPay(e.value as number)
          }}
        />
        <Button
          label={'Guardar'}
          onClick={() => {
            updatePayment(confirmationDialogVisible, managing_zc, yearToPay)

            const costEntry = {
              date: new Date(),
              description: `Quota Ano ${yearToPay} - Sócio ${confirmationDialogVisible.ass_nr}`,
              value: confirmationDialogVisible.quota,
              supplier: confirmationDialogVisible.name,
              quantity: 1,
              type: 'in',
            }
            setYearToPay(Number(new Date().getFullYear()))
            addCosts(costEntry, managing_zc)
            setConfirmationDialogVisible(false)
          }}
        />
      </Dialog>
      <TabMenu
        model={tabItems}
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      />
      <DataTable
        value={memoSocios}
        stripedRows
        size="normal"
        scrollable
        scrollHeight="79vh"
      >
        <Column sortable field="name" header="Nome" />
        <Column
          sortable
          field="entry_date"
          body={(rowData: any) =>
            new Date(rowData.entry_date).toLocaleDateString('pt-PT')
          }
          header="Data Admissão"
        />
        <Column sortable field="ass_nr" header="Número de Sócio" />
        <Column field="quota" header="Valor" />
        <Column
          field="payments"
          style={{ maxWidth: '200px' }}
          body={renderPaymentsPills}
          header="Quotas Pagas"
        />
        <Column
          field="payments"
          style={{ maxWidth: '200px' }}
          body={renderMissingPaymentsPills}
          header="Em Falta"
        />
        <Column body={renderCurrentYearPayment} header="Época Corrente" />
      </DataTable>
    </Wrapper>
  )
}

export default Payments

const Wrapper = styled.div`
  padding: 10px;
`
