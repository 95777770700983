import { useState, useEffect, useRef } from 'react'

import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'

import styled from 'styled-components'
import { Sidebar } from 'primereact/sidebar'
import { InputText } from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'
import { Calendar } from 'primereact/calendar'
import { InputSwitch } from 'primereact/inputswitch'

import { toast } from 'react-toastify'

import { useCostStore, useUserStore } from './DataStore'

const costFormInitial = {
  date: new Date(),
  description: '',
  value: undefined,
  supplier: '',
  quantity: undefined,
  type: 'out',
}

const Costs = () => {
  const costs = useCostStore((state: any) => state.costs)
  const fetchCosts = useCostStore((state: any) => state.fetch)
  const deleteCosts = useCostStore((state: any) => state.delete)
  const addCosts = useCostStore((state: any) => state.add)

  const managing_zc = useUserStore((state: any) => state.managing_zc)

  let calendarElement = useRef<any>(undefined)

  const [addCost, setAddCost] = useState(false)

  const [costForm, setCostForm] = useState(costFormInitial)

  const subtractMonths = (date: Date, months: number) => {
    const dateCopy = new Date(date)

    dateCopy.setMonth(dateCopy.getMonth() - months)

    return dateCopy
  }

  const [dates, setDates] = useState<Array<Date | null | undefined>>([
    subtractMonths(new Date(), 1),
    new Date(),
  ])

  useEffect(() => {
    if (!costs) {
      fetchCosts(managing_zc, dates)
    }
  }, [costs, dates, fetchCosts, managing_zc])

  const isFormValid = () => {
    const temp = Object.values(costForm).filter((item) => item)

    let result = false

    if (temp.length !== Object.keys(costFormInitial).length) {
      toast.error('Tem de preencher todos os campos')
      result = false
    } else {
      result = true
    }

    return result
  }

  const deleteBodyTemplate = (rowData: any) => (
    <Button
      size="small"
      icon="pi pi-times"
      severity="danger"
      onClick={() => deleteCosts(rowData.id, managing_zc)}
    />
  )

  const formatCurrency = (value: string) => {
    return parseFloat(value).toLocaleString('pt-PT', {
      style: 'currency',
      currency: 'EUR',
    })
  }

  const priceBodyTemplate = (product: any) => {
    return (
      <span
        style={{
          color: product.type === 'in' ? 'green' : 'red',
          fontWeight: 'bold',
        }}
      >
        {formatCurrency(product.value)}
      </span>
    )
  }

  const saveAsExcelFile = (buffer: BlobPart, fileName: string) => {
    import('file-saver').then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        let EXCEL_EXTENSION = '.xlsx'
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        })

        module.default.saveAs(
          data,
          fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
        )
      }
    })
  }

  const translateCosts = (rawCosts: any) => {
    let receita = 0
    let despesa = 0

    const translatedCosts = rawCosts.map((cost: any) => {
      if (cost.type === 'in') {
        receita = receita + parseFloat(cost.value)
      } else {
        despesa = despesa + parseFloat(cost.value)
      }

      return {
        Data: new Date(cost.date).toLocaleDateString('pt-PT'),
        'Processo ZC': cost.process_id,
        Descrição: cost.description,
        Valor: cost.value,
        Quantidade: cost.quantity,
        Fornecedor: cost.supplier,
        Tipo: cost.type === 'in' ? 'Receita' : 'Despesa',
      }
    })

    return [
      ...translatedCosts,
      { 'Total Despesa': despesa, 'Total Receita': receita },
    ]
  }

  const exportExcel = () => {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(translateCosts(costs))
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] }
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      })

      saveAsExcelFile(excelBuffer, 'Contabilidade')
    })
  }

  const tableHeader = (
    <ActionContainer>
      <LeftActionContainer>
        <Calendar
          ref={calendarElement}
          dateFormat="dd/mm/yy"
          placeholder="Intervalo de Datas"
          value={dates as Array<Date>}
          selectionMode="range"
          onChange={(e) => {
            const start = (e.value as any)[0]
            const end = (e.value as any)[1]
            if (start && end) {
              calendarElement.current.hide()
              fetchCosts(managing_zc, [start, end])
            }
            setDates(e.value as Array<Date>)
          }}
        />
        <Button
          size="small"
          style={{ marginLeft: '10px' }}
          type="button"
          severity="success"
          onClick={exportExcel}
          label="Exportar Contabilidade"
        />
      </LeftActionContainer>
      <Button
        label={'Adicionar Entrada'}
        icon="pi pi-plus"
        onClick={() => setAddCost(true)}
      />
    </ActionContainer>
  )

  return (
    <Wrapper>
      <Sidebar
        visible={addCost}
        position="right"
        onHide={() => {
          setAddCost(false)
          setCostForm(costFormInitial)
        }}
        style={{ width: '500px' }}
      >
        <h2>Adicionar Custo</h2>
        <p style={{ fontWeight: 'bold' }}>Data</p>
        <Calendar
          style={{ width: '100%' }}
          value={costForm.date}
          placeholder="Data"
          onChange={(e) => setCostForm({ ...costForm, date: e.value as Date })}
          dateFormat="dd/mm/yy"
        />
        <p style={{ fontWeight: 'bold' }}>Tipo</p>
        <CostTypeWrapper>
          <p>Despesa</p>
          <InputSwitch
            checked={costForm.type === 'in'}
            onChange={(e) =>
              setCostForm({
                ...costForm,
                type: Boolean(e.value) ? 'in' : 'out',
              })
            }
          />
          <p>Receita</p>
        </CostTypeWrapper>
        <p style={{ fontWeight: 'bold' }}>Descrição</p>
        <InputText
          style={{ width: '100%' }}
          value={costForm.description}
          placeholder="Descrição"
          onChange={(e) =>
            setCostForm({ ...costForm, description: e.target.value })
          }
        />
        <p style={{ fontWeight: 'bold' }}>Quantidade</p>
        <InputNumber
          style={{ width: '100%' }}
          useGrouping={false}
          value={costForm.quantity}
          placeholder="Quantidade"
          onChange={(e) =>
            setCostForm({ ...costForm, quantity: String(e.value) as any })
          }
        />
        <p style={{ fontWeight: 'bold' }}>Valor</p>
        <InputNumber
          mode="currency"
          currency="EUR"
          locale="pt-PT"
          style={{ width: '100%' }}
          useGrouping={false}
          value={costForm.value}
          placeholder="Valor"
          onChange={(e) =>
            setCostForm({ ...costForm, value: String(e.value) as any })
          }
        />
        <p style={{ fontWeight: 'bold' }}>Fornecedor</p>
        <InputText
          style={{ width: '100%' }}
          value={costForm.supplier}
          placeholder="Fornecedor"
          onChange={(e) =>
            setCostForm({ ...costForm, supplier: e.target.value })
          }
        />
        <Button
          style={{ width: '100%', marginTop: '20px' }}
          label={'Adicionar Entrada'}
          onClick={() => {
            if (isFormValid()) {
              addCosts(costForm, managing_zc)
              setCostForm(costFormInitial)
              setAddCost(false)
            }
          }}
        />
      </Sidebar>
      <DataTable
        value={costs}
        stripedRows
        size="normal"
        scrollable
        scrollHeight="77vh"
        header={tableHeader}
      >
        <Column
          sortable
          field="date"
          body={(rowData: any) =>
            new Date(rowData.date).toLocaleDateString('pt-PT')
          }
          header="Data"
        />
        <Column
          field="type"
          body={(rowData: any) =>
            rowData.type === 'in' ? 'Receita' : 'Despesa'
          }
          header="Tipo"
        />
        <Column field="description" header="Descrição" />
        <Column field="quantity" header="Quantidade" />
        <Column
          sortable
          field="value"
          header="Valor"
          body={priceBodyTemplate}
        />
        <Column field="supplier" header="Fornecedor" />
        <Column body={deleteBodyTemplate} header="Remover Entrada" />
      </DataTable>
    </Wrapper>
  )
}

export default Costs

const Wrapper = styled.div`
  padding: 10px;
`
const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CostTypeWrapper = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0 5px;
  }
`

const LeftActionContainer = styled.div`
  display: flex;
  align-items: center;
`
