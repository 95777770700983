import {
  Container,
  ColunaImagem,
  Paragrafo,
  Titulo,
  Linha,
  Textos,
} from "./styled";
import imagem from "../images/illustration-hero.svg";

export default function About() {
  return (
    <Container>
      <Linha>
        <Textos>
          <Titulo>HuntiGest - Gestão Cinegética agora mais fácil</Titulo>
          <Paragrafo>Desde o controlo de despesas e receitas, controlo de sócios e pagamentos de quotas ... não esquecendo a organização de montarias e a criação de credenciais automáticas.</Paragrafo>
        </Textos>
        <ColunaImagem>
          <img src={imagem} alt="bookmark" />
        </ColunaImagem>
      </Linha>
    </Container>
  );
}
