import { create } from 'zustand'
import { toast } from 'react-toastify'
import { createClient } from '@supabase/supabase-js'

const supabase = createClient(process.env.REACT_APP_SUP_API_URL as string, process.env.REACT_APP_SUP_ANON_KEY as string)

const systemError = (message?: string) => toast.error(message || 'Ocorreu um problema com a base de dados, tente novamente.')
const apiError = (message?: string) => toast.error(message || 'Ocorreu um problema com a base de dados, tente novamente.')
const success = (message: string) => toast.success(message)

const useAssociateStore = create((set) => ({
  associates: undefined,
  resetAssociateStore: () => set(() => ({ associates: undefined })),
  fetch: async (zc: string) => {

    try {
      const { data, error } = await supabase
      .from('ass')
      .select('*')
      .eq('zc', zc)
  
      if (error) {
        apiError()
      }

      set({ associates: data })
    } catch (err) {
      systemError()
    }

  },
  delete: async (id: string, zc: string) => {

    try {
      const { error } = await supabase
      .from('ass')
      .delete()
      .eq('license_id', id)
      .eq('zc', zc)
  
      if (error) {
        apiError()
      }
      success('Sócio removido com sucesso!')
      set({ associates: undefined })
    } catch (err) {
      systemError()
    }

  },
  add: async (form: Record<string, any>, zc: string) => {

    try {
    const { error } = await supabase
    .from('ass')
    .insert([
      {...form, zc, entry_date: new Date(), payments: []}
    ])

    if (error) {
      apiError()
    }

    success('Sócio adicionado com sucesso!')
    set({ associates: undefined })
  } catch (err) {
    systemError()
  }
    
  },
  update: async (userRow: any) => {

    const id = userRow.id
    const zc = userRow.zc

    let ass = userRow
    delete ass.id
    delete ass.zc

    try {
      
      const { error } = await supabase
      .from('ass')
      .update(ass)
      .eq('id', id)
      .eq('zc', zc)
  
      if (error) {
        apiError()
      }
  
      success('Sócio atualizado')
      set({ associates: undefined })
    } catch (err) {
      systemError()
    }

  },
  updatePayment: async (userRow: any, zc: string, year: number) => {

    try {
      const { error } = await supabase
      .from('ass')
      .update({ payments: [...userRow.payments, year] })
      .eq('zc', zc)
      .eq('license_id', userRow.license_id)
  
      if (error) {
        apiError()
      }
  
      success('Quota atualizada')
      set({ associates: undefined })
    } catch (err) {
      systemError()
    }

  },
}))

const useKMLStore = create((set) => ({
  kml: undefined,
  resetKMLStore: () => set(() => ({ kml: undefined })),
  fetch: async (zc: string) => {

    try {
      const { data, error } = await supabase
      .from('zcs')
      .select('kml_url')
      .eq('process_id', zc)
  
      if (error) {
        apiError()
      }
      
      set({ kml: data && data[0].kml_url })
    } catch (err) {
      systemError()
    }

  },
}))

const useCostStore = create((set) => ({
  costs: undefined,
  resetCostStore: () => set(() => ({ costs: undefined })),
  fetch: async (zc: string, dates: Array<Date>) => {

    try {
      const { data, error } = await supabase
      .from('cst')
      .select('*')
      .eq('process_id', zc)
      .gte('date', dates[0].toLocaleDateString('en-US'))
      .lte('date', dates[1].toLocaleDateString('en-US'))
  
      if (error) {
        apiError()
      }
      set({costs: data})
    } catch (err) {
      systemError()
    }

  },
  delete: async (id: string, zc: string) => {

    try {
      const { error } = await supabase
      .from('cst')
      .delete()
      .eq('id', id)
      .eq('process_id', zc)
  
      if (error) {
        apiError()
      }
      success('Custo removido com sucesso!')
      set({costs: undefined})
    } catch (err) {
      systemError()
    }

  },
  add: async (form: Record<string, any>, process_id: string) => {

    try {
    const { error } = await supabase
    .from('cst')
    .insert([
      {...form, process_id, date: new Date(form.date).toLocaleDateString('en-US')}
    ])
  
      if (error) {
        apiError()
      }
      success('Custo adicionado com sucesso!')
      set({costs: undefined})
    } catch (err) {
      systemError()
    }

  },
}))

const useZCStore = create((set) => ({
  zc: undefined,
  ass_contracted: 0,
  resetZCSStore: () => set(() => ({ zc: undefined, ass_contracted: 0 })),
  fetch: async (id: string) => {

    try {
      const { data, error } = await supabase
      .from('zcs')
      .select('*')
      .eq('process_id', id)

      if (error) {
        apiError()
      }

      const localData = data as any

      if (localData) {
        set({zc: localData[0], ass_contracted: localData[0].ass_contracted})
      }

      
    } catch (err) {
      systemError()
    }

  }
}))

const useUserStore = create((set, get) => ({
  user: undefined,
  managing_zc: undefined,
  available_sms: 0,
  uid: undefined,
  available_zcs: [],
  fetch: async (id: string) => {

    try {
      const { data, error } = await supabase
      .from('usr')
      .select('*')
      .eq('uid', id)

      if (error) {
        apiError()
      }
      const localData = data as any
      const userInfo = localData && localData[0] ? localData[0] : undefined

      if (userInfo) {
        set({user: userInfo, available_zcs: userInfo.zcs, managing_zc: userInfo.zcs[0], available_sms: userInfo.available_sms, uid: userInfo.uid})
      }

    } catch (err) {
      systemError()
    }

  },
  updateSmsPackage: async (count: number, uid: string) => {
      
      await supabase
      .from('usr')
      .update({'available_sms': count})
      .eq('uid', uid)
  
      set({ available_sms: count })

  },
  setManagingZc: (managing_zc: any) => set(() => ({ managing_zc })),
  setUser: (user: any) => set(() => ({ user })),
  resetUser: () => set(() => ({ user: undefined,
    managing_zc: undefined,
    available_sms: 0,
    uid: undefined,
    available_zcs: [] }))
}))

const useDrivenStore = create((set) => ({
  hunts: undefined,
  managing_hunt: undefined,
  lines: undefined,
  monteiros: undefined,
  setManaging_hunt: (managing_hunt: any) => set(() => ({ managing_hunt })),
  resetDrivenStore: () => set(() => ({ hunts: undefined,
    managing_hunt: undefined,
    lines: undefined,
    monteiros: undefined, })),
  resetManagingHunt: () => set(() => ({ managing_hunt: undefined, lines: undefined, monteiros: undefined })),
  fetch: async (id: string) => {

    try {
      const { data, error } = await supabase
      .from('mnt')
      .select('*')
      .eq('process_id', id)

      if (error) {
        apiError()
      }
      set({hunts: data})
    } catch (err) {
      systemError()
    }

  },
  fetchMonteiros: async (hunt_id: string, process_id: string) => {

    try {
      const { data, error } = await supabase
      .from('monteiros')
      .select('*')
      .eq('hunt_id', hunt_id)
      .eq('process_id', process_id)

      if (error) {
        apiError()
      }
      set({monteiros: data})
    } catch (err) {
      systemError()
    }

  },
  delete: async (id: string, zc: string) => {

    try {
      const { error } = await supabase
      .from('mnt')
      .delete()
      .eq('id', id)
      .eq('process_id', zc)

      await supabase
      .from('monteiros')
      .delete()
      .eq('hunt_id', id)
      .eq('process_id', zc)
  
      if (error) {
        apiError()
      }
      success('Montaria removida com sucesso!')
      set({ hunts: undefined })
    } catch (err) {
      systemError()
    }

  },
  add: async (form: Record<string, any>, zc: string) => {

    try {
    const { error } = await supabase
    .from('mnt')
    .insert([
      {...form, process_id: zc, date: new Date(form.date).toLocaleDateString('en-US')}
    ])

    if (error) {
      apiError()
    }

    success('Montaria adicionada com sucesso!')
    set({ hunts: undefined })
  } catch (err) {
    systemError()
  }
    
  },
  updateLines: async (lines: any, id: string, zc: string) => {

    try {
      
      const { error } = await supabase
      .from('mnt')
      .update({lines})
      .eq('id', id)
      .eq('process_id', zc)
  
      if (error) {
        apiError()
      }
  
      success('Linhas atualizadas')
      set({ lines: undefined })
    } catch (err) {
      systemError()
    }

  },
  fetchSingle: async (id: string, process_id: string) => {

    try {
      const { data, error } = await supabase
      .from('mnt')
      .select('*')
      .eq('process_id', process_id)
      .eq('id', id)

      if (error) {
        apiError()
      }

      const localData: any = data && data[0]

      if (localData) {
        set({managing_hunt: localData, lines: localData.lines})
      }

    } catch (err) {
      systemError()
    }

  },
  addMonteiro: async (form: Record<string, any>, hunt_id: string, zc: string) => {

    try {
    const { error } = await supabase
    .from('monteiros')
    .insert([
      {...form, process_id: zc, hunt_id }
    ])

    if (error) {
      apiError()
    }

    success('Monteiro adicionado com sucesso!')
    set({ monteiros: undefined })
  } catch (err) {
    systemError()
  }
    
  },
  deleteMonteiro: async (id: string, hunt_id: string, zc: string) => {

    try {
      const { error } = await supabase
      .from('monteiros')
      .delete()
      .eq('id', id)
      .eq('process_id', zc)
      .eq('hunt_id', hunt_id)
  
      if (error) {
        apiError()
      }
      success('Monteiro removido com sucesso!')
      set({ monteiros: undefined })
    } catch (err) {
      systemError()
    }

  },
  updateLineDoorMonteiro: async (id: string, line: string, door: string, paid: number, hunt_id: string, zc: string) => {

    try {
      
      const { error } = await supabase
      .from('monteiros')
      .update({line, door, paid})
      .eq('id', id)
      .eq('hunt_id', hunt_id)
      .eq('process_id', zc)
  
      if (error) {
        apiError()
      }
  
      success('Posição atualizada')
    } catch (err) {
      systemError()
    }

  },
}))

export { useAssociateStore, useKMLStore, useCostStore, useUserStore, useZCStore, useDrivenStore }

